<template>
  <div class="container">
    <a-button
      @click="$router.back()"
      style="border-radius: 20px; background-image: linear-gradient(to right, #1D2671 , #830051); color: white;margin-bottom: 10px;"
      ><LeftOutlined /> Back</a-button
    >
    <div
      class="card w-100 d-flex flex-column flex-sm-row align-items-center justify-content-center p-4 p-xl-5"
      style="border-radius: 24px;"
    >
      <div class="row my-3 mb-sm-5 px-5">
        <div
          class="col-12 col-md-5 p-0 d-flex flex-column align-items-center justify-content-center "
        >
          <div class="d-flex align-items-center justify-content-center">
            <img
              src="../../../assets/img/pasien_berhenti.png"
              alt=""
              :class="$style['img-banner']"
            />
          </div>
        </div>
        <div
          class="col-12 col-md-7 p-0 d-flex flex-column align-items-center justify-content-center "
        >
          <h2 class="text-center my-5" :class="$style['banner-title']">
            <strong>Program Anda telah diberhentikan.</strong>
          </h2>
          <h6 style="text-align: center;" class="mb-3">
            Apa alasan anda berhenti dari program ini?
          </h6>
          <div class="row w-100 mb-4">
            <div
              class="col-12 col-md-6 my-3 d-flex flex-column align-items-center justify-content-center "
              style="height: 50px;"
              v-for="(reason, idx) in terminateReason"
              :key="idx"
            >
              <a-button class="w-100 h-100" @click="handleMessage(reason.label)">
                <p class="m-0" style="font-size: 13px;">{{ reason.label }}</p>
              </a-button>
            </div>
          </div>
          <div class="row w-100 text-center">
            <div class="col-12">
              <a-button
                type="primary"
                html-type="submit"
                :class="$style['custom-button-yes']"
                :loading="loading"
                @click="submitTerminate"
                :disabled="!selectedMessage"
              >
                <strong>KIRIM TANGGAPAN</strong>
              </a-button>
            </div>
            <!-- <div class="col-6 ">
          <a-button
            type="primary"
            html-type="submit"
            :class="$style['custom-button-no']"
            :loading="loading"
          >
            <strong>TUTUP</strong>
          </a-button>
        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  onMounted,
  onBeforeUnmount,
  ref,
  reactive,
  createVNode,
  toRef,
  toRefs,
  watch,
  computed,
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Banner from '../../../components/Banners'
import { baseURL } from '@/services/axios'

import {
  CheckOutlined,
  ToolOutlined,
  PhoneOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  InfoCircleOutlined,
  HomeOutlined,
  ContactsOutlined,
  LeftOutlined,
} from '@ant-design/icons-vue'
import { Modal, notification, message } from 'ant-design-vue'
import { useForm } from '@ant-design-vue/use'
import axios from 'axios'
import { getAssets, getCustomer } from '../../../services/axios/modc/assets'
import lottie from '../../../../scripts/Timer.json'
import caution from '../../../../scripts/Caution.json'
import { getTerminateReason, terminateProgram } from '../../../services/axios/pulih'

export default {
  components: {
    Banner,
    LeftOutlined,
  },
  props: {
    step: {
      type: String, // You can change this type according to your actual data type
      required: true, // Make it required if it always needs to be passed
    },
  },

  setup(props) {
    /* eslint-disable */
    const route = useRoute()
    const router = useRouter()
    const step = props.step
    let store = useStore()
    let settings = computed(() => store.getters.settings)
    const dataHasilTes = computed(() => store.state.dataHasilTes)
    const bannerStep = computed(() => store.state.bannerStep)
    const isRedemption = computed(() => store.state.isRedemption)
    const programId = computed(() => store.state.programId)
    const isExtendingExist = computed(() => store.state.isExtendingExist)
    let checked = ref(false)
    let strRentangUmur = ref({
      pertama: '< 40 tahun',
      kedua: '41-50 tahun',
      ketiga: '> 50 tahun',
    })
    const totalPasien = ref(400)
    const rataSkor = ref(8.7)
    const pendingVerifikasi = ref(2)
    const patientTiering = ref({
      silver: 1,
      gold: 2,
      platinum: 5,
    })
    const handleMenu = type => {
      console.log(type)
      if (type === 'profil') {
        router.push('/profil')
      } else if (type === 'program-pulih') {
        router.push('/program/' + 'PROGRAMTYPE/' + '1b76ef5b-3e5e-4ac8-b6b4-b1eb27c5c842')
      } else if (type === 'scan') {
        router.push('/scan/')
      } else if (type === 'schedule') {
        router.push('/scheduling')
      }
    }
    const selectedMessage = ref('')
    const handleMessage = message => {
      selectedMessage.value = message
    }
    const submitTerminate = async () => {
      const res = await terminateProgram(programId.value, selectedMessage.value)
      if (res) {
        store.dispatch('changeRedemptionStatus', false)
        store.dispatch('changeRedemptionStatusExist', false)
        store.dispatch('updateJadwalExist', false)
        store.dispatch('changeIsAuroraStatus', false)
        store.dispatch('updateIsExtendingExist', false)
        store.dispatch('changeBannerStep', 0)
      }
      console.log(res)
      router.push('/dashboard')
    }
    const terminateReason = ref([])
    onMounted(async () => {
      const { data } = await getTerminateReason()
      terminateReason.value = data
      console.log(data, 'reasonterminates')
    })
    // onBeforeUnmount(() => {})

    return {
      selectedMessage,
      submitTerminate,
      handleMessage,
      terminateReason,
      isExtendingExist,
      programId,
      isRedemption,
      bannerStep,
      step,
      dataHasilTes,
      handleMenu,
      checked,
      totalPasien,
      rataSkor,
      pendingVerifikasi,
      patientTiering,
      strRentangUmur,
      settings,
      lottie,
      caution,
    }
  },
}
</script>

<style lang="scss" module>
@import '@/components/Auth/style.module.scss';

.main-menu-item {
  width: 100%;
  height: auto;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.main-menu-item:hover {
  transform: scale(1.05);
  /* Slightly increase the size */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.24);
  /* Optionally enhance the shadow */
}

.title-section {
  font-size: 20px;
  color: #4f125f;
  width: 200px;
}

.custom-card {
  position: relative;
  overflow: hidden;
  /* Memastikan isi tidak melebihi batas card */
  border-radius: 17px;
}

.custom-card img {
  width: 100%;
  height: auto;
  /* Sesuaikan dengan kebutuhan, bisa juga height: 100% jika perlu */
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, #800152, transparent);
  width: 100%;
  height: 100%;
  /* Atur sesuai kebutuhan untuk efek gradient */
  z-index: 1;
}

.card-title {
  position: absolute;
  bottom: 10px;
  left: 30px;
  color: white;
  z-index: 2;
  /* Pastikan teks berada di atas overlay */
  width: 100%;
  /* Sesuaikan lebar teks */
}

/* Menargetkan elemen checkbox internal secara global */
:global(.ant-checkbox-inner) {
  width: 20px !important;
  /* Mengatur lebar */
  height: 20px !important;
  /* Mengatur tinggi */
  border-radius: 3px !important;
  /* Opsional: Menghapus border-radius */

  /* Center horizontal */
  border: 1px solid #707072 !important;
}

/* Opsi tambahan: Menyesuaikan ukuran tanda centang jika perlu */
:global(.ant-checkbox-inner::after) {
  /* Atur lebar tanda centang */
  // width: 20px !important;
  // height: 20px !important;
  /* Atur tinggi tanda centang */
  /* Pusatkan secara vertikal */
  // border-radius: 8px !important;
  display: flex !important;
  /* Menggunakan flexbox untuk align centang */
  align-items: center !important;
  /* Center vertical */
  justify-content: center !important;
  // top: 20px !important;
  left: 5px !important;
  // transform: scale(1) !important;
  // rotate: 40deg;
  /* Menyesuaikan posisi dan skala */
}

.custom-input-email-login {
  border: none !important;
  border-bottom: 1px solid #e4e9f0 !important;
  border-radius: 0 !important;

  &:focus {
    border-bottom: 1px solid #800152 !important;
  }

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-input-password-login {
  border: none !important;
  border-bottom: 1px solid #e4e9f0 !important;
  border-radius: 0 !important;

  &:focus-within {
    border-bottom: 1px solid #800152 !important;
  }

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-forget-pass {
  color: #800152 !important;
  border-bottom: 1px solid transparent;
  transition: 0.3s all ease-in-out;

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-register-link {
  color: #800152 !important;
  font-weight: 700;
  border-bottom: 1px solid transparent;
  transition: 0.3s all ease-in-out;

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-form-item {
  label::before {
    content: '' !important;
  }
}

.custom-button-yes {
  width: 100%;

  height: 40px !important;
  font-weight: 500;
  font-size: 12px !important;
  text-transform: uppercase;
  background: linear-gradient(to right, #eda702, #c76b1e) !important;
  border: 1px solid #eda702 !important;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #eda702 !important;
    background: transparent !important;
  }
}

.custom-button-no {
  // width: 100%;
  height: 20px !important;
  font-weight: 500;
  font-size: 8px !important;
  text-transform: uppercase;
  background: linear-gradient(to right, #800251, #ba0672) !important;
  transition: all 0.3s ease-in-out;
  border: 1px solid #800251 !important;

  &:global(.ant-btn-primary:hover) {
    border: 1px solid #800251 !important;
  }

  &:hover {
    color: #800251 !important;
    background: transparent !important;
    border: 1px solid #800251 !important;
  }
}

.custom-container-login {
  width: 75% !important;
}

.custom-text-login {
  color: white;
}

@media (max-width: 960px) {
  .custom-container-login {
    width: 100% !important;
  }
}

.step-title {
  font-size: 18px;
}

.img-banner {
  width: 130px;
  height: auto;
  object-fit: cover;
}

.banner-title {
  font-size: 14px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
}

.line {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  background-color: #4f4f4f;
  height: 1px;
}

.img-step {
  width: 32px;
  height: 32px;
}

.step p {
  font-size: 8px;
}

.img-clock {
  width: 38px;
  height: 35px;
}

.konsumsi-section {
  height: 70px;
}

.img-obat {
  width: 27px;
  height: 46px;
}

.text-obat {
  font-size: 12px;
}

.konsumsi-kanan {
  background-color: #ffffff;
  border-radius: 0 20px 20px 0;
  padding: 20px 30px 20px 30px;
}

.article-item {
  width: 280px;
  height: 130px;
  border-radius: 20px;
  margin-top: 10px;
}

.menu-title {
  font-size: 10px;
  height: auto;
}

//DEKSTOP
@media screen and (min-width: 1440px) {
  .main-menu-item {
    width: 100%;
    height: auto;
    object-fit: cover;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 30px;
  }

  .title-section {
    font-size: 32px;
    color: #4f125f;
    width: auto;
  }

  .img-banner {
    width: 327.75px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 50px;
  }

  .banner-title {
    font-size: 36px;
    width: 100%;
  }

  .custom-button-yes {
    height: 60px !important;
    width: 100%;
    font-size: 24px !important;
  }

  .custom-button-no {
    height: 60px !important;
    width: 100%;
    font-size: 24px !important;
    // margin-top: 5px;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
  }

  .line {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    background-color: #4f4f4f;
    height: 1px;
  }

  .img-step {
    width: 64px;
    height: 64px;
  }

  .step p {
    font-size: 16px;
  }

  .title-step {
    height: 240px;
  }

  .step-title {
    font-size: 32px;
    width: 130px;
    text-align: center;
  }

  .menu-title {
    font-size: 18px;
  }

  .img-clock {
    width: 88px;
    height: 85px;
  }

  .konsumsi-section {
    height: 200px;
  }

  .img-obat {
    width: 65px;
    height: 100px;
  }

  .text-obat {
    font-size: 24px;
  }

  .konsumsi-kanan {
    background-color: #ffffff;
    border-radius: 0 20px 20px 0;
    padding: 50px 70px 50px 70px;
  }

  .article-item {
    width: 400px;
    height: 200px;
    border-radius: 20px;
    margin-top: 10px;
  }

  /* Menargetkan elemen checkbox internal secara global */
  :global(.ant-checkbox-inner) {
    width: 56px !important;
    /* Mengatur lebar */
    height: 56px !important;
    /* Mengatur tinggi */
    border-radius: 8px !important;
    /* Opsional: Menghapus border-radius */

    /* Center horizontal */
    border: 1px solid #707072 !important;
  }

  /* Opsi tambahan: Menyesuaikan ukuran tanda centang jika perlu */
  :global(.ant-checkbox-inner::after) {
    /* Atur lebar tanda centang */
    // width: 20px !important;
    // height: 20px !important;
    /* Atur tinggi tanda centang */
    /* Pusatkan secara vertikal */
    // border-radius: 8px !important;
    display: flex !important;
    /* Menggunakan flexbox untuk align centang */
    align-items: center !important;
    /* Center vertical */
    justify-content: center !important;
    top: 20px !important;
    left: 24px !important;
    transform: scale(3) !important;
    rotate: 40deg;
    /* Menyesuaikan posisi dan skala */
  }
}

//TABLET
@media screen and (max-width: 1439px) and (min-width: 768px) {
  .main-menu-item {
    width: 100%;
    height: auto;
    object-fit: cover;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px;
  }

  .title-section {
    font-size: 32px;
    color: #4f125f;
    width: auto;
  }

  .img-banner {
    width: 200px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .step-title {
    font-size: 32px;
    // width: 130px;
    text-align: center;
  }

  .banner-title {
    font-size: 16px;
    width: 100%;
  }

  .custom-button-yes {
    height: 30px !important;
    width: 100%;
    font-size: 12px !important;
  }

  .custom-button-no {
    height: 30px !important;
    width: 100%;
    font-size: 12px !important;
    margin-top: 5px;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
  }

  .line {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
    background-color: #4f4f4f;
    height: 1px;
  }

  .img-step {
    width: 50px;
    height: 50px;
  }

  .step p {
    font-size: 12px;
  }

  .title-step {
    height: 240px;
  }

  .img-clock {
    width: 48px;
    height: 45px;
  }

  .konsumsi-section {
    height: 100px;
  }

  .article-item {
    width: 280px;
    height: 130px;
    border-radius: 20px;
    margin-top: 10px;
  }
}
</style>
